<template>
  <div>
    <!-- begin::Card -->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            Registro de visita para
            <span
                :class="`font-weight-bold text-h5 ${'blue--text text--darken-3'}`"
            >persona externa</span
            >

            <span class="d-block text-muted pt-2 font-size-sm"
            >Puede registrar asistencia, el detalle de visita y medicamentos
              suministrados.
            </span>
          </h3>
        </div>
        <v-btn color="primary" class="mt-5" @click="returnTo('dashboard')">
          Regresar al dashboard
        </v-btn>
      </div>
      <div class="card-body">
        <!-- Begin:: External person autocomplete -->
        <template>
          <v-autocomplete
              v-if="!addNewExternalClinicVisit"
              :label="
              externalPeople.isLoading
                ? 'Cargado personas auxiliadas previamente...'
                : 'Nombre de persona'
            "
              :loading="externalPeople.isLoading"
              :disabled="externalPeople.isLoading || !externalPeople?.data.length"
              :filter="customFilterExternalPeople"
              rounded
              filled
              hide-details
              open-on-clear
              clearable
              :items="externalPeople.data"
              item-text="full_name"
              item-value="id"
              return-object
              v-model="selectedExternalPerson"
          >
            <!-- begin::selected student item -->
            <template v-slot:selection="{ item, index }">
              <v-chip
                  class="ml-n2 mt-2"
                  color="primary"
                  small
                  v-if="index === 0"
              >
                <span> {{ item.full_name }}</span>
              </v-chip>
            </template>
            <!-- end::selected student item -->

            <template v-slot:item="{ item }">
              <v-row>
                <v-col class="student-divider mt-3 mb-3">
                  <v-row>
                    <!-- student photo -->
                    <!-- -------------------------- -->
                    <v-col
                        cols="12"
                        class="d-flex flex-row align-center justify-start pb-1"
                    >
                      <v-avatar size="50" color="primary lighten-5">
                        <v-icon large color="primary"
                        >mdi-account-circle-outline
                        </v-icon
                        >
                      </v-avatar>
                      <span class="text-uppercase font-weight-medium ml-2">
                        {{ item.full_name }}
                      </span>
                    </v-col>
                    <!-- -------------------------- -->
                  </v-row>
                </v-col>
              </v-row>
            </template>
          </v-autocomplete>
        </template>

        <!-- End:: External person autocomplete -->
        <div v-if="addNewExternalClinicVisit">
          <v-timeline dense align-top>
            <v-divider class="mt-n6"></v-divider>
            <!-- Begin:: Optional first step - register external person -->
            <v-timeline-item color="primary">
              <v-row>
                <v-col cols="12">
                  <p class="text-h5 font-weight-bold">
                    {{
                      registerExternalPeople ? "Registro de persona" : "Persona"
                    }}
                  </p>
                </v-col>
              </v-row>
              <template v-if="registerExternalPeople">
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <form-group
                        :validator="$v.externalPerson.first_name"
                        name="nombre"
                    >
                      <v-text-field
                          slot-scope="{ attrs }"
                          v-model.trim="externalPerson.first_name"
                          filled
                          label="Nombre"
                          rounded
                          v-bind="attrs"
                      ></v-text-field>
                    </form-group>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <form-group
                        :validator="$v.externalPerson.last_name"
                        name="apellido"
                    >
                      <v-text-field
                          slot-scope="{ attrs }"
                          v-model.trim="externalPerson.last_name"
                          filled
                          label="Apellido"
                          rounded
                          v-bind="attrs"
                      ></v-text-field>
                    </form-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <form-group
                        :validator="$v.externalPerson.classification_id"
                        name="clasificación"
                    >
                      <v-select
                          :items="externalPeopleclassification.data"
                          :loading="externalPeopleclassification.isLoading"
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          label="Clasificación"
                          item-value="id"
                          item-text="name"
                          v-model="externalPerson.classification_id"
                          hide-details
                          filled
                          rounded
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip color="primary" small v-if="index === 0">
                            <span>{{ item.name }}</span>
                          </v-chip>
                        </template>
                      </v-select>
                    </form-group>
                  </v-col>
                </v-row>
              </template>
              <template v-else>
                <v-row>
                  <v-col>
                    <v-alert icon="mdi-account" prominent text type="info">
                      <span
                          class="text-uppercase font-weight-medium text-center"
                      >
                        {{ selectedExternalPerson.first_name }}
                        {{ selectedExternalPerson.last_name }}
                      </span>
                      <strong class="ml-1">{{
                          selectedExternalPerson[
                              "ExternalPeopleClassification.name"
                              ]
                        }}</strong>
                    </v-alert>
                  </v-col>
                </v-row>
              </template>
            </v-timeline-item>
            <!-- End:: Optional first step - register external person -->
            <v-timeline-item color="primary">
              <v-row>
                <v-col cols="12">
                  <p class="text-h5 mb-4 mt-2 font-weight-bold">
                    Registro de signos vitales
                  </p>
                </v-col>
              </v-row>
              <v-row class="mb-8">
                <v-col cols="12" md="9" class="py-0 d-flex align-center">
                  <form-group
                      :validator="$v.selectedVitalSign"
                      name="signo vital"
                  >
                    <v-select
                        slot-scope="{ attrs }"
                        v-bind="attrs"
                        label="Signos vitales"
                        :items="vitalSigns.data"
                        item-value="id"
                        item-text="name"
                        v-model="selectedVitalSign"
                        hide-details
                        filled
                        rounded
                        return-object
                        @click="clearSelectedVitalSign()"
                        @change="openVitalSignsRegistrationModal()"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                      </template>
                    </v-select>
                  </form-group>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          :disabled="clinicVisit.vital_sign_id.length > 0"
                          v-bind="attrs"
                          v-on="on"
                          @click="showMdlVitalSigns"
                          icon
                          color="amber"
                          class="ml-3"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar signos vitales</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row v-if="clinicVisit.vital_sign_id.length > 0">
                <v-col cols="12">
                  <v-card class="rounded-lg ml-4">
                    <p class="text-h6 font-weight-bold pt-4 mb-0 pl-5">
                      Signos vitales registrados:
                    </p>
                    <v-list two-line>
                      <v-scroll-y-transition
                          :hide-on-leave="true"
                          :leave-absolute="true"
                          group
                      >
                        <v-list-item
                            v-for="(
                            recordedVitalSign, index
                          ) in clinicVisit.vital_sign_id"
                            :key="index"
                        >
                          <v-list-item-avatar color="primary lighten-5">
                            <v-icon color="primary" dark> mdi-pulse</v-icon>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title>{{
                                recordedVitalSign.name
                              }}
                            </v-list-item-title>
                            <v-list-item-subtitle
                            >{{ recordedVitalSign.measurement }}
                              {{ recordedVitalSign.unit }}
                            </v-list-item-subtitle
                            >
                          </v-list-item-content>

                          <v-list-item-icon>
                            <v-tooltip color="red" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    color="red"
                                    @click="
                                    removeVitalSignFormList(
                                      recordedVitalSign.id
                                    )
                                  "
                                >
                                  <v-icon color="red">mdi-delete</v-icon>
                                </v-btn>
                              </template>
                              <span>Remover de la lista</span>
                            </v-tooltip>
                          </v-list-item-icon>
                        </v-list-item>
                      </v-scroll-y-transition>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </v-timeline-item>
            <v-timeline-item color="primary">
              <v-row>
                <v-col cols="12">
                  <p class="text-h5 mb-4 mt-2 font-weight-bold">
                    Detalle de visita
                  </p>
                </v-col>
              </v-row>
              <v-row class="mb-8">
                <v-col cols="12" md="10" class="py-0 d-flex align-center">
                  <form-group
                      :validator="$v.clinicVisit.disease_id"
                      name="padecimiento"
                  >
                    <v-autocomplete
                        slot-scope="{ attrs }"
                        v-bind="attrs"
                        label="Padecimiento"
                        :items="diseases.data"
                        item-value="id"
                        item-text="name"
                        v-model="selectedIllness"
                        hide-details
                        clearable
                        filled
                        rounded
                        return-object
                        @change="saveDiseasesRecord(selectedIllness)"
                        @click:clear="resetSelectedIllnes()"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </form-group>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          :disabled="clinicVisit.disease_id.length > 0"
                          v-bind="attrs"
                          v-on="on"
                          @click="showMdlDiseases"
                          icon
                          color="amber"
                          class="ml-3"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar lista de padecimientos</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-0">
                  <form-group
                      name="detalle de visita"
                      :validator="$v.clinicVisit.diagnostic"
                  >
                    <v-textarea
                        slot-scope="{ attrs }"
                        v-bind="attrs"
                        counter
                        filled
                        clearable
                        rounded
                        auto-grow
                        rows="2"
                        label="Detalle de visita"
                        v-model.trim="clinicVisit.diagnostic"
                        @blur="$v.clinicVisit.diagnostic.$touch()"
                        @paste="handlePaste($event, 'diagnostic')"
                    ></v-textarea>
                  </form-group>
                </v-col>
              </v-row>
            </v-timeline-item>
            <v-timeline-item color="primary">
              <v-row>
                <v-col cols="12">
                  <p class="text-h5 mb-4 mt-2 font-weight-bold">
                    Registro de medicamentos suministrados
                  </p>
                </v-col>
              </v-row>
              <v-row class="mb-8">
                <v-col cols="12" md="9" class="py-0 d-flex align-center">
                  <form-group
                      :validator="$v.clinicVisit.disease_id"
                      name="medicamento"
                  >
                    <v-autocomplete
                        slot-scope="{ attrs }"
                        v-bind="attrs"
                        label="Medicamento"
                        :items="medicines.data"
                        item-value="id"
                        item-text="name"
                        v-model="selectedMedicine"
                        hide-details
                        filled
                        rounded
                        return-object
                        @click="clearSelectedMedicine()"
                        @change="openDosisRegistrationModal()"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </form-group>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          :disabled="clinicVisit.medication_detail.length > 0"
                          v-bind="attrs"
                          v-on="on"
                          icon
                          @click="showMdlMedicines"
                          color="amber"
                          class="ml-3"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar lista de medicamentos</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row v-if="clinicVisit.medication_detail.length > 0">
                <v-col cols="12">
                  <v-card class="rounded-lg ml-4">
                    <p class="text-h6 font-weight-bold pt-4 mb-0 pl-5">
                      Medicamentos suministrados registrados:
                    </p>
                    <v-list two-line>
                      <v-scroll-y-transition
                          :hide-on-leave="true"
                          :leave-absolute="true"
                          group
                      >
                        <v-list-item
                            v-for="(
                            givenMedicine, index
                          ) in clinicVisit.medication_detail"
                            :key="index"
                        >
                          <v-list-item-avatar color="primary lighten-5">
                            <v-icon color="primary" dark> mdi-pill</v-icon>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title>{{
                                givenMedicine.name
                              }}
                            </v-list-item-title>
                            <v-list-item-subtitle>{{
                                givenMedicine.dosis_supplied
                              }}
                            </v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-icon>
                            <v-tooltip color="red" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    color="red"
                                    @click="
                                    removeGivenMedicineFormList(
                                      givenMedicine.medicine_id
                                    )
                                  "
                                >
                                  <v-icon color="red">mdi-delete</v-icon>
                                </v-btn>
                              </template>
                              <span>Remover de la lista</span>
                            </v-tooltip>
                          </v-list-item-icon>
                        </v-list-item>
                      </v-scroll-y-transition>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
          <v-row justify="center">
            <v-col cols="5">
              <v-btn
                  block
                  outlined
                  class="mt-3"
                  color="primary"
                  @click="returnTo('newVisit')"
              >
                Nueva visita
              </v-btn>
            </v-col>
            <v-col cols="5">
              <v-btn block class="mt-3" color="primary" @click="submit">
                Registrar visita
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <div class="d-flex flex-column py-10 align-center px-md-15">
            <v-avatar color="green lighten-4" size="96" class="mt-10 mb-5">
              <v-icon large color="green"> mdi-account-search-outline</v-icon>
            </v-avatar>

            <p class="text-body-1 text-center mx-md-15">
              Puede encontrar el registro de una persona externa por medio de su
              nombre y/o apellido. Por otro lado, si desea añadir el registro de
              una nueva persona externa haga clic en el botón
              <span class="font-weight-bold">"Nueva visita"</span>.
            </p>

            <v-btn class="mt-4" @click="registerExternalPeopleAndVisit()"
            >Nueva visita
            </v-btn
            >
          </div>
        </div>
      </div>
    </div>
    <NewClinicVisitConfirmationModal
        ref="mdlNewVisitConfirmation"
        :buttonClicked="handleReturn"
        @action="handleClickReturn"
    ></NewClinicVisitConfirmationModal>
    <RegisterGivenDosisModal
        ref="mdlRegisterGivenDosis"
        @action="saveGivenDosisRecord($event)"
        @clear-selection="clearSelectedMedicine"
        :givenMedicine="selectedMedicine"
    >
    </RegisterGivenDosisModal>
    <RegisterVitalSignModal
        ref="mdlRegisterVitalSign"
        @action="saveVitalSignRecord($event)"
        @clear-selection="clearSelectedVitalSign"
        :recordedVitalSign="selectedVitalSign"
    >
    </RegisterVitalSignModal>

    <ManageMedicineModal
        ref="mdlMedicine"
        :medicinesInfo="medicines"
        @reload="getMedicines"
    ></ManageMedicineModal>
    <ManageDiseaseModal
        ref="mdlDisease"
        :diseasesInfo="diseases"
        @reload="getDiseases"
    ></ManageDiseaseModal>
    <ManageVitalSignsModal
        ref="mdlVistalSigns"
        :vitalSignsInfo="vitalSigns"
        @reload="getVitalSigns"
    >
    </ManageVitalSignsModal>
  </div>
</template>

<script>
import {required, requiredIf} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import nursingRepository from "@/repositories/nursingRepository";
import RegisterGivenDosisModal from "@/components/modals/medical_visit/RegisterGivenDosisModal";
import RegisterVitalSignModal from "@/components/modals/medical_visit/RegisterVitalSignModal";
import NewClinicVisitConfirmationModal from "@/components/modals/clinical_nursing/NewClinicVisitConfirmationModal.vue";
import axios from "axios";
import ManageDiseaseModal from "@/components/modals/clinical_nursing/ManageDiseaseModal.vue";
import ManageMedicineModal from "@/components/modals/clinical_nursing/ManageMedicineModal.vue";
import ManageVitalSignsModal from "@/components/modals/clinical_nursing/ManageVitalSignsModal.vue";

export default {
  name: "NewExternalClinicalVisitForm",
  title: "Unidad de primeros auxilios | GE ITR",

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Unidad de primeros auxilios", route: "/clinical_nursing"},
      {
        title: "Visita Externa",
        route: "new_external_clinical_visit",
      },
    ]);
    this.loadRegisteredExternalPeople();
    this.getMedicines();
    this.getDiseases();
    this.getVitalSigns();
  },

  components: {
    RegisterGivenDosisModal,
    RegisterVitalSignModal,
    NewClinicVisitConfirmationModal,
    ManageDiseaseModal,
    ManageMedicineModal,
    ManageVitalSignsModal,
  },
  data() {
    return {
      addNewExternalClinicVisit: false,
      registerExternalPeople: false,

      selectedExternalPerson: {},
      selectedMedicine: {},
      selectedIllness: {},
      selectedVitalSign: {},

      externalPeople: {
        isLoading: false,
        data: [],
      },

      externalPerson: {
        first_name: null,
        last_name: null,
        classification_id: null,
      },

      externalPeopleclassification: {
        data: [],
        isLoading: false,
      },

      clinicVisit: {
        patient_id: null,
        patient_type: "ExternalPatient", //Puede ser "ExternalPatient" ó "Student"
        visit_date_time: null,
        diagnostic: null,
        user_id: null,
        medication_detail: [],
        disease_id: [],
        vital_sign_id: [],
      },

      medicines: {
        data: [],
        isLoading: false,
      },
      diseases: {
        data: [],
        isLoading: false,
      },
      vitalSigns: {
        data: [],
        isLoading: false,
      },

      handleReturn: null,
    };
  },

  watch: {
    selectedExternalPerson(newValue) {
      if (!!newValue) {
        if (Object.entries(newValue).length > 0) {
          this.unregisteredPerson = true;
          this.addNewExternalClinicVisit = true;
          this.registerExternalPeople = false;
          this.clinicVisit.patient_id = newValue.id;
        } else {
          this.unregisteredPerson = false;
          this.addNewExternalClinicVisit = false;
          this.registerExternalPeople = true;
        }
      }
    },

    registerExternalPeople(newValue) {
      if (newValue) {
        this.loadExternalPeopleClassifications();
      }
    },
  },

  methods: {
    getVitalSigns() {
      this.vitalSigns.isLoading = true;
      nursingRepository
          .getAllVitalSigns()
          .then(({data}) => {
            this.vitalSigns.data = data.data;
          })
          .catch(() => {
            this.fireToast({
              icon: "error",
              title: "No ha sido posible cargar los signos vitales",
            });
          })
          .finally(() => {
            this.vitalSigns.isLoading = false;
          });
    },
    async getMedicines() {
      try {
        this.medicines.isLoading = true;
        const res = await axios.get(`${this.getFormApiURL}/medicines`);
        this.medicines.data = res.data;
        this.medicines.isLoading = false;
      } catch (error) {
        console.log("ERROR: ", error);
        this.medicines.isLoading = false;
        this.fireToast({
          icon: "error",
          title: "No ha sido posible cargar los medicamentos",
        });
      }
    },
    async getDiseases() {
      try {
        this.diseases.isLoading = true;
        const res = await axios.get(`${this.getFormApiURL}/diseases`);
        this.diseases.data = res.data;
        this.diseases.isLoading = false;
      } catch (error) {
        this.diseases.isLoading = false;
        this.fireToast({
          icon: "error",
          title: "No ha sido posible cargar los medicamentos",
        });
      }
    },
    handleClickReturn(condition) {
      if (condition === "dashboard") {
        this.returnToDashboard();
        this.clear();
      } else if (condition === "newVisit") {
        this.clear();
      }
    },
    returnTo(condition) {
      if (condition === "dashboard") {
        this.handleReturn = "dashboard";
      } else if (condition === "newVisit") {
        this.handleReturn = "newVisit";
      }
      this.$refs.mdlNewVisitConfirmation.toggleModal();
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar los campos requeridos",
        });
        return;
      }
      if (this.registerExternalPeople) {
        this.registerExternalPerson();
      } else {
        this.saveClinicVisit();
      }
    },
    registerExternalPerson() {
      nursingRepository
          .createExternalPerson(this.externalPerson)
          .then(({data}) => {
            let externalPeopleId = data.externa_people.id;
            this.clinicVisit.patient_id = externalPeopleId;
            this.saveClinicVisit();
          })
          .catch(() => {
            this.fireToast({
              icon: "error",
              title: "No se pudo registrar a la persona",
            });
          });
    },
    saveClinicVisit() {
      let visitDate = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
      );
      let hours = String(visitDate.getUTCHours()).padStart(2, "0");
      let minutes = String(visitDate.getUTCMinutes()).padStart(2, "0");
      let seconds = String(visitDate.getUTCSeconds()).padStart(2, "0");
      this.clinicVisit.visit_date_time = `${visitDate
          .toISOString()
          .substring(0, 10)}  ${hours}:${minutes}:${seconds}`;
      visitDate = this.clinicVisit.user_id =
          this.currentUserPersonalInfo.id_user;
      nursingRepository
          .createClinicVisit(this.clinicVisit)
          .then(({data}) => {
            this.sweetAlertResponse(data);
            this.clear();
            this.$router.push({
              path: "/clinical_nursing",
            });
          })
          .catch(() => {
            this.fireToast({
              icon: "error",
              title:
                  "No se pudo registrar la visita a unidad de primeros auxilios",
            });
          });
    },
    /****fetching external people and core users****/
    loadRegisteredExternalPeople() {
      this.externalPeople.isLoading = true;
      nursingRepository
          .getAllExternalPeople()
          .then(({data}) => {
            this.externalPeople.data = data.data;
          })
          .catch(() => {
            this.fireToast({
              icon: "error",
              title: `No ha sido posible cargar los datos desde el servidor`,
            });
          })
          .finally(() => {
            this.externalPeople.isLoading = false;
          });
    },
    loadExternalPeopleClassifications() {
      this.externalPeopleclassification.isLoading = true;
      nursingRepository
          .getAllExternalPeopleClassifications()
          .then(({data}) => {
            this.externalPeopleclassification.data = data.data;
          })
          .catch(() => {
            this.fireToast({
              icon: "error",
              title: "No ha sido posible cargar las clasificaciones",
            });
          })
          .finally(() => {
            this.externalPeopleclassification.isLoading = false;
          });
    },
    registerExternalPeopleAndVisit() {
      //Se tiene que activar el apartado para registrar persna externa
      this.addNewExternalClinicVisit = true;
      this.registerExternalPeople = true;
    },

    customFilterExternalPeople(item, queryText) {
      const fullName = item.full_name.toLowerCase();
      const query = queryText.toLowerCase();
      return fullName.includes(query);
    },

    handlePaste(event) {
      event.preventDefault();
      let paste = (event.clipboardData || window.clipboardData).getData("text");
      paste = paste.replace(/ {3,}/g, "  ");
      this.clinicVisit.diagnostic = this.clinicVisit.diagnostic + paste;
    },
    //------------------MEDICINES--------------------
    openDosisRegistrationModal() {
      if (this.selectedMedicine !== null) {
        this.$refs.mdlRegisterGivenDosis.toggleModal();
      }
    },
    saveGivenDosisRecord(givenMedicineWithDosis) {
      this.clinicVisit.medication_detail.push(givenMedicineWithDosis);
    },
    clearSelectedMedicine() {
      this.selectedMedicine = {};
    },
    removeGivenMedicineFormList(selectedGivenMedicineID) {
      const indexToRemove = this.clinicVisit.medication_detail.findIndex(
          (item) => item.medicine_id === selectedGivenMedicineID
      );
      if (indexToRemove !== -1) {
        this.clinicVisit.medication_detail.splice(indexToRemove, 1);
      }
    },
    //-------------------DISEASES----------------------
    resetSelectedIllnes() {
      this.selectedIllness = {};
      this.clinicVisit.disease_id = [];
    },
    saveDiseasesRecord(selectedIllnessFormDiagnostic) {
      if (selectedIllnessFormDiagnostic === null) {
        return;
      }
      this.clinicVisit.disease_id = [];
      this.clinicVisit.disease_id.push({
        id: selectedIllnessFormDiagnostic.id,
      });
    },
    //------------------VITAL-SIGNS---------------------
    openVitalSignsRegistrationModal() {
      this.$refs.mdlRegisterVitalSign.toggleModal();
    },
    saveVitalSignRecord(vitalSignRecord) {
      this.clinicVisit.vital_sign_id.push(vitalSignRecord);
    },
    clearSelectedVitalSign() {
      this.selectedVitalSign = {};
    },
    removeVitalSignFormList(selectedVitalSignRecordID) {
      const indexToRemove = this.clinicVisit.vital_sign_id.findIndex(
          (item) => item.id === selectedVitalSignRecordID
      );
      if (indexToRemove !== -1) {
        this.clinicVisit.vital_sign_id.splice(indexToRemove, 1);
      }
    },
    clear() {
      this.selectedExternalPerson = {};
      this.selectedDisease = {};
      this.clearSelectedMedicine();
      this.clearSelectedVitalSign();
      this.$v.$reset();
    },
    returnToDashboard() {
      this.$router.push({
        path: "/clinical_nursing",
      });
    },

    showMdlDiseases() {
      this.$refs.mdlDisease.toggleModal();
    },

    showMdlMedicines() {
      this.$refs.mdlMedicine.toggleModal();
    },

    showMdlVitalSigns() {
      this.$refs.mdlVistalSigns.toggleModal();
    },
  },

  computed: {
    ...mapGetters(["nursingCatalogsInfo", "currentUserPersonalInfo"]),
    DP_URL() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.DP_API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.DP_DEV_API_URL;
      } else {
        baseURL = process.env.DP_LOCAL_API_URL;
      }
      return baseURL;
    },
    getFormApiURL() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.ADMISION_API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.ADMISION_DEV_API_URL;
      } else {
        baseURL = process.env.ADMISION_LOCAL_API_URL;
      }
      return baseURL;
    },
  },
  validations: {
    externalPerson: {
      first_name: {
        required: requiredIf(function () {
          return !!this.registerExternalPeople;
        }),
      },
      last_name: {
        required: requiredIf(function () {
          return !!this.registerExternalPeople;
        }),
      },
      classification_id: {
        required: requiredIf(function () {
          return !!this.registerExternalPeople;
        }),
      },
    },
    clinicVisit: {
      patient_id: {},
      patient_type: {},
      visit_date_time: {},
      diagnostic: {required},
      user_id: {},
      medication_detail: {},
      disease_id: {},
      vital_sign_id: {},
    },
  },
};
</script>
