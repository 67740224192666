<template>
  <v-dialog v-model="dialog" width="550">
    <v-card class="elevation-0 pt-8 pb-2">
      <v-card-text>
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <v-avatar color="indigo lighten-4" size="85">
              <v-icon dark large color="indigo">mdi-pulse</v-icon>
            </v-avatar>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
          <v-col cols="11">
            <p class="text-center text-h5 font-weight-bold">
              Registro de signo vital <br />
              <span class="indigo--text text-h4 font-weight-bold text--darken-1"
                >"{{ recordedVitalSign?.name }}"</span
              >
            </p>
            <p class="text-body-1 text-center font-weight-medium">
              Ingrese la magnitud de la medición.
            </p>
            <form-group :validator="$v.vitalSignMagnitud" name="dosis">
              <v-text-field
                slot-scope="{ attrs }"
                v-model.trim="vitalSignMagnitud"
                filled
                label="Medición de signo vital"
                rounded
                v-bind="attrs"
                type="number"
                :suffix="`${recordedVitalSign.measurement_unit}`"
              ></v-text-field>
            </form-group>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="font-weight-medium" text outlined @click="dialog = false">
          cancelar registro
        </v-btn>
        <v-btn
          color="indigo white--text font-weight-medium"
          @click="emitAction()"
        >
          registrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "RegisterVitalSignModal",
  data() {
    return {
      dialog: false,
      vitalSignMagnitud: "",
    };
  },

  props: {
    recordedVitalSign: {
      type: Object,
    },
  },

  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
      this.resetModal();
    },

    resetModal() {
      this.vitalSignMagnitud = "";
      this.$v.$reset();
    },

    emitAction() {
      this.$v.vitalSignMagnitud.$touch();

      if (!this.$v.vitalSignMagnitud.$invalid) {
        let registeredVitalSigns = {
          name: this.recordedVitalSign.name,
          unit: this.recordedVitalSign.measurement_unit,
          id: this.recordedVitalSign.id,
          measurement: this.vitalSignMagnitud,
        };
        this.$emit("action", registeredVitalSigns);
        this.toggleModal();
        this.$emit("clear-selection");
        return;
      }
    },
  },

  validations: {
    vitalSignMagnitud: { required },
  },
};
</script>
