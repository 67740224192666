<template>
  <v-dialog v-model="dialog" width="550">
    <v-card class="elevation-0 pt-8 pb-2">
      <v-card-text>
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <v-avatar color="primary lighten-4" size="85">
              <v-icon dark large color="primary"> mdi-pill-multiple </v-icon>
            </v-avatar>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
          <v-col cols="11">
            <p class="text-center text-h5 font-weight-bold">
              Registro de dosis suministrada de <br />
              <span class="blue--text text-h4 font-weight-bold text--darken-1"
                >"{{ givenMedicine?.name }}"</span
              >
            </p>
            <p class="text-body-1 text-center font-weight-medium">
              Ingrese la magnitud de la dosis suministrada seguida de la unidad
              de medida. (Ej. 500 mg, 10 ml, 2 comprimidos, etc)
            </p>
            <form-group :validator="$v.givenDosis" name="dosis">
              <v-text-field
                slot-scope="{ attrs }"
                v-model.trim="givenDosis"
                filled
                label="Dosis suministrada"
                rounded
                v-bind="attrs"
              ></v-text-field>
            </form-group>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="font-weight-medium" text outlined @click="dialog = false">
          cancelar registro
        </v-btn>
        <v-btn
          color="primary white--text font-weight-medium"
          @click="emitAction()"
        >
          registrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "RegisterGivenDosisModal",
  data() {
    return {
      dialog: false,
      givenDosis: "",
    };
  },

  props: {
    givenMedicine: {
      type: Object,
    },
  },

  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
      this.resetModal();
    },

    resetModal() {
      this.givenDosis = "";
      this.$v.$reset();
    },

    emitAction() {
      this.$v.givenDosis.$touch();

      if (!this.$v.givenDosis.$invalid) {
        let givenMedicineWithDosis = {
          name: this.givenMedicine.name,
          medicine_id: this.givenMedicine.id,
          dosis_supplied: this.givenDosis,
        };
        this.$emit("action", givenMedicineWithDosis);
        this.toggleModal();
        this.$emit("clear-selection");
        return;
      }
    },
  },

  validations: {
    givenDosis: { required },
  },
};
</script>
